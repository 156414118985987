<template>
  <div>
    趋势图
  </div>
</template>

<script>
export default {
  name: 'trending'
}
</script>

<style lang="less" scoped>

</style>
